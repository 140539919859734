import React, { useEffect } from "react";

const AppTest = () => {
  useEffect(() => {
    console.log("Hello world!");
  }, []);
  return (
    <div>
      <h1 style={{ color: "black" }}>Hello World!</h1>
      <h1 style={{ color: "black" }}>
        This is a second Div with another hello world!
      </h1>
    </div>
  );
};

export default AppTest;
