import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import React from 'react';

const AppLineChat = ({ showLines }) => {
	const data = [
		{
			name: '1',
			value: 2000,
		},
		{
			name: '',
			value: 4000,
		},
		{
			name: 'Page C',
			value: 3000,
		},
		{
			name: 'Page D',
			value: 8080,
		},
		{
			name: 'Page E',
			value: 7090,
		},
		{
			name: 'Page F',
			value: 12900,
		},
		{
			name: 'Page G',
			value: 13900,
		},
	];
	return (
		<div style={{ width: '100%', height: 320 }}>
			<ResponsiveContainer width="100%" height="100%">
				<LineChart
					width={500}
					height={300}
					data={data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					{/* <CartesianGrid strokeDasharray="3 3" /> */}
					{showLines && (
						<>
							<XAxis dataKey="name" />
							<YAxis />
						</>
					)}
					<Tooltip />
					{/* <Legend /> */}
					{/* <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
					<Line type="monotone" dataKey="value" strokeWidth={3} stroke="#82ca9d" dot={{ r: 0 }} />
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
};

export default AppLineChat;
