import { createBrowserRouter } from "react-router-dom";
import DashboardLayout from "./layouts/dashbordlayout";
import LandingPage from "./pages/LandingPage/landingPage";
import AnalyticsPage from "./pages/app/analytics";
import JournalPage from "./pages/app/journal";
import OverviewPage from "./pages/app/overview";
import ProjectionPage from "./pages/app/projection";
import SettingPage from "./pages/app/settings";
import SignInPage from "./pages/auth/signIn";
import SignUpPage from "./pages/auth/signUp";
import AddJournal from "./pages/app/addJournal";
import ForgetPassword from "./pages/auth/forgetPassword";
import TrackersPage from "./pages/app/tracker";
import TrackerDeatials from "./pages/app/trackerDetails";
import TestPage from "./Components/test";
import TopNav from "./pages/LandingPage/Sections/LandingNav";
import AffiliateDashboard from "./pages/app/affiliate";
import ContactPage from "./pages/Contact/contact";
import PricingPage from "./pages/Pricing/pricing";
import AdminDashboard from "./pages/app/admin";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
  },
  {
    path: "/pricing",
    element: <PricingPage />,
  },
  {
    path: "/signup",
    element: <SignUpPage />,
  },
  {
    path: "/signin",
    element: <SignInPage />,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "/share/:trackerID",
    element: (
      <div className="share_page_bg">
        <TopNav />
        <TrackerDeatials />
      </div>
    ),
  },
  {
    path: "/app",
    element: <DashboardLayout />,
    children: [
      {
        path: "overview",
        element: <OverviewPage />,
      },
      {
        path: "journals",
        element: <JournalPage />,
      },
      {
        path: "journals/add",
        element: <AddJournal />,
      },
      {
        path: "journals/update/:journalId",
        element: <AddJournal />,
      },
      {
        path: "tracker",
        element: <TrackersPage />,
      },
      {
        path: "tracker/:trackerID",
        element: <TrackerDeatials />,
      },
      {
        path: "analytics",
        element: <AnalyticsPage />,
      },
      {
        path: "projection",
        element: <ProjectionPage />,
      },
      {
        path: "settings",
        element: <SettingPage />,
      },
      {
        path: "test",
        element: <TestPage />,
      },
      {
        path: "admin",
        element: <AdminDashboard />
      },
      {
        path: "affiliate",
        element: <AffiliateDashboard />
      }
    ]
  }
],
);
