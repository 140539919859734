// import React, { useEffect, useState } from 'react';
// import dayjs from 'dayjs';
// import AppLineChat from './lineChart';
// import { useAuthContext } from '../context/auth-context/AuthProvider';
// import { getJournals } from '../firebase/firebaseFunctions';

// const DateArrayComponent = ({ filter }) => {
// 	const [dateArray, setDateArray] = useState([]);

// 	const generateDateArray = () => {
// 		const currentDate = dayjs();
// 		let startDate;

// 		switch (filter) {
// 			case 'last7days':
// 				startDate = currentDate.subtract(6, 'days').startOf('day');
// 				break;
// 			case 'last30days':
// 				startDate = currentDate.subtract(29, 'days').startOf('day');
// 				break;
// 			case 'last90days':
// 				startDate = currentDate.subtract(89, 'days').startOf('day');
// 				break;
// 			case 'last180days':
// 				startDate = currentDate.subtract(179, 'days').startOf('day');
// 				break;
// 			default:
// 				startDate = currentDate;
// 		}

// 		const dates = [];
// 		let currentDateInterval = startDate;

// 		while (currentDateInterval.isBefore(currentDate) || currentDateInterval.isSame(currentDate, 'day')) {
// 			dates.push({ date: currentDateInterval.format('DD MMM YYYY') });
// 			switch (filter) {
// 				case 'last7days':
// 				case 'last30days':
// 					currentDateInterval = currentDateInterval.add(1, 'day');
// 					break;
// 				case 'last90days':
// 				case 'last180days':
// 					currentDateInterval = currentDateInterval.add(6, 'day');
// 					break;
// 				default:
// 					break;
// 			}
// 		}

// 		setDateArray(dates);
// 	};

// 	const { user } = useAuthContext();
// 	const [dataLoading, setDataLoading] = useState([]);

// 	const getJournalsFunc = async () => {
// 		setDataLoading(true);
// 		try {
// 			const data = await getJournals(user?.uid);
// 			setJournalData(data);
// 			generateDateArray();
// 		} catch (error) {
// 		} finally {
// 			setDataLoading(false);
// 		}
// 	};

// 	useEffect(() => {
// 		getJournalsFunc();
// 	}, [filter]);

// 	return <AppLineChat />;
// };

// export default DateArrayComponent;

import React, { useState } from 'react';
import dayjs from 'dayjs';

function TestPage() {
	const [startDate, setStartDate] = useState('');
	const [numberOfDays, setNumberOfDays] = useState(1);
	const [includeWeekends, setIncludeWeekends] = useState(false);
	const [endDate, setEndDate] = useState('');
	const [datesArray, setDatesArray] = useState([]);

	const handleStartDateChange = (event) => {
		setStartDate(event.target.value);
	};

	const handleNumberOfDaysChange = (event) => {
		setNumberOfDays(parseInt(event.target.value));
	};

	const handleIncludeWeekendsChange = (event) => {
		setIncludeWeekends(event.target.checked);
	};

	const generateDateRange = () => {
		let start = dayjs(startDate);
		let currentDate = start;
		const datesArray = [];
		let daysAdded = 0;

		while (daysAdded < numberOfDays) {
			if (!includeWeekends && (currentDate.day() === 0 || currentDate.day() === 6)) {
				// Skip weekends if includeWeekends is false
				currentDate = currentDate.add(1, 'day');
				continue;
			}
			datesArray.push(currentDate.format('YYYY-MM-DD'));
			currentDate = currentDate.add(1, 'day');
			daysAdded++;
		}

		setDatesArray(datesArray);
	};

	return (
		<div className="App">
			<h1>Date Range Generator</h1>
			<label>
				Start Date:
				<input type="date" value={startDate} onChange={handleStartDateChange} />
			</label>
			<br />
			<label>
				Number of Days:
				<input type="number" value={numberOfDays} onChange={handleNumberOfDaysChange} />
			</label>
			<br />
			<label>
				Include Weekends:
				<input type="checkbox" checked={includeWeekends} onChange={handleIncludeWeekendsChange} />
			</label>
			<br />
			<button onClick={generateDateRange}>Generate End Date</button>
			{datesArray.length > 0 && (
				<ul>
					{datesArray.map((date) => (
						<li key={date}>{date}</li>
					))}
				</ul>
			)}
		</div>
	);
}

export default TestPage;
