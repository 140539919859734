import InfoRateCard from '../../../Components/infoRateCard';
import ProjectionCard from '../../../Components/projectionCard';
import { rightAcoordion } from '../../../assets/svg';
import { Select } from 'antd';
import AppLineChat from '../../../Components/lineChart';
import { useEffect, useState } from 'react';
import { deleteProjection, getDashboardData } from '../../../firebase/firebaseFunctions';

import './styles.scss';
import { useAuthContext } from '../../../context/auth-context/AuthProvider';
import dayjs from 'dayjs';
import TradeCard from '../../../Components/tradeCard';
import RecomendationCard from '../../../Components/recomemdedationCard';
import JournaDetailsDrawer from '../../../Components/journalDrawer';
import Tracker from '../../../Components/trackerCard';
import { useNotificationContext } from '../../../context/notification-context/NotificationProvider';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;
const OverviewPage = () => {
	const [data, setData] = useState(null);
	const [dataLoading, setDataLoading] = useState(false);
	const { user } = useAuthContext();
	const { showErrorMessage, showSuccessMessage } = useNotificationContext();
	const navigate = useNavigate();
	const [openTradeDetailsDrawer, setOpenTradeDetailsDrawer] = useState(false);
	const [selectedJournal, setSelectedJournal] = useState({});
	const getDashboardDataFunc = async () => {
		setDataLoading(true);
		try {
			const res = await getDashboardData(user?.uid);
			setData(res);
		} catch (error) {
			// console.log(error);
		} finally {
			setDataLoading(false);
		}
	};
	const deleteProjectionFunc = async (projectionId) => {
		try {
			await deleteProjection(projectionId);
			showSuccessMessage('Projection Deleted!');
			setData((prev) => ({ ...prev, projections: prev.projection?.filter((p) => p.id !== projectionId) }));
		} catch (err) {
			showErrorMessage(err?.message);
		}
	};

	useEffect(() => {
		getDashboardDataFunc();
		// eslint-disable-next-line
	}, []);
	return (
		<>
			<div className="overview_page">
				<span className="date">Todays date: {dayjs().format('DD MMMM YYYY')}</span>
				<div className="top_cards">
					<InfoRateCard
						skLoading={dataLoading}
						title="Trade Taken"
						percentage={`+${data?.total_trade_taken?.current_week || 0} this week`}
						value={data?.total_trade_taken?.total || '0'}
						valueGrowth={true}
					/>
					<InfoRateCard
						skLoading={dataLoading}
						title="Pair Traded"
						percentage={`+${data?.pair_traded?.current_week || 0} this week`}
						value={data?.pair_traded?.total || '0'}
						valueGrowth={true}
					/>
					<InfoRateCard
						skLoading={dataLoading}
						title="Profit"
						percentage={`+${data?.profitable_trades?.current_week || 0} this week`}
						value={data?.profitable_trades?.total || '0'}
						valueGrowth={true}
					/>
					<InfoRateCard
						skLoading={dataLoading}
						title="Loss"
						percentage={`+${data?.loss_trades?.current_week || 0} this week`}
						value={data?.loss_trades?.total || '0'}
						valueGrowth={false}
					/>
					<InfoRateCard
						skLoading={dataLoading}
						title="Breakeven"
						percentage={`+${data?.breakeven_trades?.current_week || 0} this week`}
						value={data?.breakeven_trades?.total || '0'}
						valueGrowth={true}
					/>
				</div>

				<Tracker />
				<div className="trade_details_wrapper">
					<div className="chart_wrapper">
						<div className="chart_top">
							<span className="title">Trading Summary</span>
							<Select value="1" className="w-100">
								<Option value="1">All</Option>
								<Option value="4">Today</Option>
								<Option value="2">Past week</Option>
								<Option value="3">Last Month</Option>
							</Select>
						</div>
						<AppLineChat showLines={true} />
					</div>
					<div className="open_trades">
						<span>Open / Pending Trade(s)</span>
						<div className="trade_list">
							{dataLoading ? (
								<>
									<TradeCard skLoading={true} />
									<TradeCard skLoading={true} />
									<TradeCard skLoading={true} />
									<TradeCard skLoading={true} />
									<TradeCard skLoading={true} />
									<TradeCard skLoading={true} />
								</>
							) : (
								<>
									{Array.isArray(data?.pendingAndInprogressJournal) && data?.pendingAndInprogressJournal.length >= 1 ? (
										<>
											{data?.pendingAndInprogressJournal.map((data, idx) => (
												<TradeCard
													key={idx}
													orderType={data?.orderType}
													currency={data?.currencyPair}
													date={dayjs(data?.timeStamp?.toDate()).format('DD MMM YYYY')}
													lotSize={data?.lotSize}
													entryPrice={data?.entryPrice}
													onClick={() => {
														setSelectedJournal(data);
														setOpenTradeDetailsDrawer(true);
													}}
												/>
											))}
										</>
									) : (
										<span className="no_item">No Pending Trades</span>
									)}
								</>
							)}
						</div>
					</div>
				</div>
				<div className="recomendation_projection">
					<div className="recomemdedation_wrapper">
						<h6>Recommended Tools</h6>
						<div className="recomemdedation_list">
							{dataLoading ? (
								<>
									<RecomendationCard skLoading={dataLoading} />
									<RecomendationCard skLoading={dataLoading} />
									<RecomendationCard skLoading={dataLoading} />
									<RecomendationCard skLoading={dataLoading} />
									<RecomendationCard skLoading={dataLoading} />
								</>
							) : (
								<>
									{Array.isArray(data?.recommendations) && data?.recommendations.length >= 1 ? (
										<>
											{data.recommendations.map((r) => (
												<RecomendationCard
													skLoading={dataLoading}
													title={r.title}
													onVisit={() => {
														window.open(r.url, '_blank');
													}}
												/>
											))}
										</>
									) : (
										<div className="empty_state">
											<span>Comming soon</span>
										</div>
									)}
								</>
							)}
						</div>
					</div>
					<div className="projection_wrapper">
						<div className="header">
							<h6>Projection</h6>
							<div className="view_all" onClick={() => navigate('/app/projection')}>
								<span>View all</span>
								{rightAcoordion}
							</div>
						</div>
						<div className="projections">
							{dataLoading ? (
								<>
									<ProjectionCard skLoading={dataLoading} />
									<ProjectionCard skLoading={dataLoading} />
									<ProjectionCard skLoading={dataLoading} />
									<ProjectionCard skLoading={dataLoading} />
									<ProjectionCard skLoading={dataLoading} />
								</>
							) : (
								<>
									{Array.isArray(data?.projections) && data?.projections?.length >= 1 ? (
										<>
											{data?.projections.map(({ subject, projection, reminderDate, reminderTime, id }) => (
												<ProjectionCard title={subject} description={projection} date={`${reminderDate} | ${reminderTime}`} onDelete={() => deleteProjectionFunc(id)} />
											))}
										</>
									) : (
										<div className="empty_state">
											<span>Comming soon</span>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			<JournaDetailsDrawer visible={openTradeDetailsDrawer} onClose={() => setOpenTradeDetailsDrawer(false)} journalObject={selectedJournal} />
		</>
	);
};

export default OverviewPage;
